<template>
  <div class="copilot-like-container d-flex flex-column min-vh-100">
    <header class="copilot-like-header bg-light py-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-4"><b>CemixBOT</b></div>
          <div class="col-1"><ArrowIcon class="pe-auto" direction="left" /></div>
          <div class="col-4 text-center">Conversaciones</div>
          <div class="col-1"><ArrowIcon class="pe-auto" direction="right" /></div>
          <div class="col-2"></div>  
        </div>
      </div>
    </header>

    <main ref="chatWindowParent" class="flex-grow-1">
      <ChatWindow :messages="messages" ref="chatWindow" />
    </main>

    <footer class="copilot-like-footer bg-light text-center py-2 mt-auto">
      <div class="container">
        <chat-input @send-message="handleSendMessage" />
        <font size="1">Powered by CEMIX &copy; 2024</font>
        <div style="display: none;">
          <SessionsComp @append-message="appendMessage" @clear-chat="cleanChatWidnow" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import ChatWindow from '@common/widgets/ChatWindow.vue';
import ChatInput from '@common/widgets/ChatInput.vue';
import SessionsComp from './components/SessionsComp.vue';
import ArrowIcon from '@common/widgets/ArrowIcon.vue';
import { ApiConsumer } from '@common/api/ApiConsumer.js';
import { checkOrCreateUniqid } from '@common/utils/cookies.js';
import { ref, nextTick } from 'vue';
import config from '@appconfig/config';

//usemos localStorage para guardar las sesiones, mensajes y la sesión seleccionada
const endpointHost = config.apiBaseUrl;
const messages = ref(JSON.parse(localStorage.getItem('messages')) || []);
const session_id = ref(localStorage.getItem('session_id') || checkOrCreateUniqid());
const apiConsumer = new ApiConsumer();
const messageEndpoint = endpointHost + '/chat/ask';
const chatWindow = ref(null); // Ref to chat element
const chatWindowParent = ref(null); // Ref to chat element parent

// Initialize the session_id cookie
const handleSendMessage = async (message) => {
  try {
    const messageObj = { id: Date.now(), text: message, class: ['message', 'question', 'sent'] };
    const response = await apiConsumer.post(messageEndpoint, { pregunta: message, session_id: session_id.value });
    if (response.status === 200) {
      const answerObj = { id: Date.now(), text: response.data.answer, class: ['message', 'answer', 'received'] };
      appendMessage(messageObj);
      appendMessage(answerObj);
    } else {
      console.error('Error sending message:', response.error);
    }
  } catch (error) {
    console.error('Error handling:', error);
  }
};

const appendMessage = (message) => {
  messages.value.push(message);
  // Scroll after DOM updates
  nextTick(() => {
    if (chatWindow.value) {
      chatWindowParent.value.scrollTop = chatWindowParent.value.scrollHeight;
    }
  });
};

const cleanChatWidnow = () => {
  messages.value = [];
};
</script>

<style scoped>
main.flex-grow-1 {
  overflow-y: auto;
  padding: 2px 4px;
}

.copilot-like-container {
  background-color: #dfedff;
  max-height: 100% !important;
  overflow-y: hidden;
}

.copilot-like-header {
  text-align: center;
  max-height: 56px;
  height: 56px;
}

.copilot-like-footer {
  text-align: center;
}

.copilot-like-title h3 {
  margin: 0;
  color: #333;
}

.copilot-like-title p {
  color: #666;
}

.copilot-like-carousel {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.carousel-item img {
  max-width: 100%;
  border-radius: 10px;
}

.carousel-item p {
  margin-top: 10px;
  font-size: 14px;
  color: #444;
}

.copilot-like-options {
  text-align: center;
  margin-bottom: 20px;
}

.copilot-like-options p {
  color: #333;
  margin-bottom: 10px;
}

.copilot-like-options button {
  background-color: #0078d4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}

.copilot-like-options button:hover {
  background-color: #005a9e;
}

.chat-input {
  margin-top: 10px;
}
</style>