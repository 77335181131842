import { createApp } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/finetuning.css';
import './assets/css/chat.css';
import App from './App.vue';
import axios from 'axios';

const app = createApp(App)

app.config.globalProperties.$axios = axios;

app.mount('#cemix-bot-app');

// Export App for use in other parts of the application
export { default as ClienteApp } from './App.vue'