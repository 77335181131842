<template>
  <div id="wrapper">
    <section>
      <ButtonContainer 
        @hide-all="hideAll"
        @show-chat="showChat"
        @show-avatar="showAvatar" 
      />
    </section>
    <section>
      <div id="chat-app" class="cemixbot-content" ref="chatApp"><ChatApp /></div>
      <div id="ai-avatar" class="cemixbot-content" ref="aiAvatar">AI Avatar Content</div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ButtonContainer from './components/ButtonContainer.vue';
import ChatApp from './components/ChatApp.vue';

const chatApp = ref(null);
const aiAvatar = ref(null);

const hideAll = () => {
  if (chatApp.value) chatApp.value.style.display = 'none';
  if (aiAvatar.value) aiAvatar.value.style.display = 'none';
};

const showChat = () => {
  hideAll();
  if (chatApp.value) chatApp.value.style.display = 'block';
  const mainElement = chatApp.value.querySelector('main');
  if (mainElement) {
    mainElement.scrollTop = mainElement.scrollHeight;
  }
};

const showAvatar = () => {
  hideAll();
  if (aiAvatar.value) aiAvatar.value.style.display = 'block';
};
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
}
#chat-app{
  height: 100%;
}
.cemixbot-content {
  display: none;
}
</style>
