<template>
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Escribe aquí..." v-model="pregunta" @keyup="handleEnter">
    <button class="btn btn-outline-secondary enviar" type="button" @click="sendMessage" >>></button>
  </div>
  <div class="input-group" style="display: none;">
    <button class="btn btn-outline-secondary" type="button" @click="cleanChatWidnow">Clear Chat</button>
  </div>

</template>

<script setup>
import { ref, defineEmits } from 'vue';

const pregunta = ref('');
const emit = defineEmits(['send-message']);

const sendMessage = () => {
  if (pregunta.value.trim()) {
    emit('send-message', pregunta.value);
    pregunta.value = '';  // Clear input after sending
  }
};

// sendMessage when hit enter and pregunta is not ''
const handleEnter = (event) => {
  if (event.key === 'Enter' && pregunta.value.trim()) {
    sendMessage();
  }
};

const cleanChatWidnow = () => {
  emit('clear-chat');
};
</script>

<style scoped>
.btn.enviar {
  background-color: #f1f1f1;
  border: 1px solid #999;
}
</style>