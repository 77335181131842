<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :fill="fill"
        :stroke="stroke"
        :stroke-width="strokeWidth"
        :stroke-linecap="strokeLinecap"
        :stroke-linejoin="strokeLinejoin"
        class="feather feather-message-circle"
        :viewBox="viewBox"
    >
        <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 018.5-8.5 8.5 8.5 0 018.5 8.5z"/>
        <circle cx="12" cy="12" r="1"/>
        <circle cx="8" cy="12" r="1"/>
        <circle cx="16" cy="12" r="1"/>
    </svg>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  width: {
    type: String,
    default: '35',
  },
  height: {
    type: String,
    default: '35',
  },
  fill: {
    type: String,
    default: 'none',
  },
  stroke: {
    type: String,
    default: 'currentColor',
  },
  strokeWidth: {
    type: String,
    default: '1.5',
  },
  strokeLinecap: {
    type: String,
    default: 'round',
  },
  strokeLinejoin: {
    type: String,
    default: 'round',
  },
  viewBox: {
    type: String,
    default: '0 0 24 24',
  },
});

const log = () => {};
log(props);
</script>
