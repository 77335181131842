<template>
    <ClienteApp />
</template>

<script setup>
import { ClienteApp } from '@cliente-embedded/main.js'
</script>

<style scoped>
</style>
  