<template>
  <div class="chat-window" ref="chatWindowDiv">
    <div v-for="(message, index) in messages" :key="index" :class="message.class.join(' ')">
      {{ message.text }}
    </div>
  </div>
</template>

<script setup>
import { ref,defineProps, watch } from 'vue';

const chatWindowDiv = ref(null);

const props = defineProps({
  messages: Array,
});

watch(
  () => props.messages,
  { deep: true }
);
</script>

<style scoped>
.chat-window{
  padding-bottom: 10px;
}
.chat-window .message{
  max-width: 85% !important;
  min-width: 100px !important;
  padding: 2px 5px;
  margin: 5px;
  border-radius: 5px;
  display: inline-block;
}

.chat-window .message.answer{
  max-width: 85% !important;
  min-width: 100px !important;
  background-color: #e2e2e2;
   float: left;
}

.chat-window .message.question{
  background-color: #ffffff;
  float: right;
  text-align: right;
}
/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For WebKit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Optional: Apply custom styles to scrollbars on hover */
*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Optional: Apply custom styles to horizontal scrollbars */
*::-webkit-scrollbar-horizontal {
  height: 12px;
}

*::-webkit-scrollbar-track-piece {
  background: #f1f1f1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:horizontal {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

*::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #555;
}

</style>
