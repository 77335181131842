<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :fill="fill"
      :stroke="stroke"
      :stroke-width="strokeWidth"
      :stroke-linecap="strokeLinecap"
      :stroke-linejoin="strokeLinejoin"
      :viewBox="viewBox"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  const props = defineProps({
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    fill: {
      type: String,
      default: 'none',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
    strokeWidth: {
      type: String,
      default: '2',
    },
    strokeLinecap: {
      type: String,
      default: 'round',
    },
    strokeLinejoin: {
      type: String,
      default: 'round',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  });

  const log = () => {};
  log(props);
</script>
  