// use axios to make the API call
import { useAxios } from "./useAxios";
const { axios } = useAxios();

// Define la clase ApiConsumer como singleton
export class ApiConsumer {
    static instance;

    constructor() {
        if (ApiConsumer.instance) {
            return ApiConsumer.instance;
        }
        ApiConsumer.instance = this;

        // Define la instancia de axios directamente en el objeto
        this.axios = axios.create({
            // Puedes añadir configuraciones globales aquí
        });
    }

    static getInstance() {
        if (!ApiConsumer.instance) {
            ApiConsumer.instance = new ApiConsumer();
        }
        return ApiConsumer.instance;
    }

    getAxiosInstance() {
        return this.axios;
    }

    async get(url, config) {
        return this.axios.get(url, config);
    }

    async post(url, data) {
        return this.axios.post(url, data);
    }

    async put(url, data) {
        return this.axios.put(url, data);
    }

    async delete(url) {
        return this.axios.delete(url);
    }
}