<template>
  <div class="button-container">
    <button class="circle-button toggler" @click="toggleButtons"></button>
    <div :class="['button-wrapper',{ collapsed: isCollapsed }]">
      <div class="buttons">
        <button class="circle-button white" @click="emitHideAll">
          <CancelSymbol width="33" height="33" />
        </button>
        <button class="circle-button white" @click="emitShowChat">
          <ChatGlobe width="35" height="35" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from 'vue';
import ChatGlobe from '@common/widgets/ChatGlobe';
import CancelSymbol from '@common/widgets/CancelSymbol';

const emit = defineEmits(['hide-all', 'show-chat', 'show-avatar']);
const isCollapsed = ref(false);

const toggleButtons = () => {
  isCollapsed.value = !isCollapsed.value;
};

const emitHideAll = () => {
  emit('hide-all');
};

const emitShowChat = () => {
  emit('show-chat');
};
</script>

<style scoped>
.button-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.button-wrapper{
  overflow: hidden;
}

.buttons {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.circle-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 5px;
  border: none;
  cursor: pointer;
}

.toggler{
  z-index: 2;
}

.collapsed {
  transform: translateX(100%);
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}
.white {
  background-color: whitesmoke;
}

.circle-button.toggler{
  background-image: url(https://cdn.edwredes.net/cemixbot/bot.png);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
