<template>
    <div>
      <!-- Conditionally render left or right arrow -->
      <svg
        v-if="direction === 'left'"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-left pe-auto"
      >
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
  
      <svg
        v-else-if="direction === 'right'"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-right pe-auto"
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    </div>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  // Define a prop for the direction
  const props = defineProps({
    direction: {
      type: String,
      required: true,
      validator: (value) => ['left', 'right'].includes(value),
    },
  });
  const prop = () => {};
  prop(props);
</script>

  