const config = {
    development: {
      apiBaseUrl: 'https://cemix-api.enthalpy.mx',
      featureFlag: false,
    },
    sandbox:{
      apiBaseUrl: 'https://cemix-api.enthalpy.mx',
      featureFlag: true,
    },
    production: {
      apiBaseUrl: 'https://cemix-api.enthalpy.mx',
      featureFlag: true,
    },
  };
  
  const env = process.env.NODE_ENV || 'development';
  
  export default config[env];
  