<template>
  <div>
    <h3>Sesiones</h3>
    <button :class="['btn','btn-success']" @click="createSession">Nueva Sesión</button>
    <button :class="['btn','btn-danger']" @click="destroyAllSessions">Eliminar sesiones</button>
    <ul>
      <a href="#" :class="['pe-auto']"><li v-for="session in sessions" :key="session" @click="selectSession(session)">Session {{ session.slice(-6) }}</li></a>
    </ul>
  </div>
</template>
  
<script setup>
  import { ref, onMounted, watch, defineEmits } from 'vue';
  import { ApiConsumer } from '@common/api/ApiConsumer';
  import { checkOrCreateUniqid } from '@common/utils/cookies.js';
  import config from '../config';

  const emit = defineEmits(['append-message', 'clear-chat', 'update:selectedSession']);

  // usemos localStorage para guardar las sesiones, mensajes y la sesión seleccionada
  const sessions = ref(JSON.parse(localStorage.getItem('sessions')) || []);
  const session_id = ref(localStorage.getItem('session_id') || '');
  const apiBaseUrl = config.apiBaseUrl;
  const urlSessions = `${apiBaseUrl}/chat/sessions`;
  const urlSessionMessages = `${apiBaseUrl}/session/messages`;

  const apiConsumer = new ApiConsumer();

  const fetchSessions = async () => {
    try {
      const uniqid = checkOrCreateUniqid();
      const product = 'product1';
      const response = await apiConsumer.get(urlSessions, {
        params: { product, uniqid },
      });

      // add fetched sessions to the sessions array
      sessions.value = response.data;

      // select the first session by default
      if (sessions.value.length > 0) {
        selectSession(sessions.value[0]);
      }else{
        createSession();
      }
    } catch (error) {
      console.error('Error fetching sessions', error);
    }
  };

  const retrieveSessionMessages = async (session) => {
    try {
      const response = await apiConsumer.get(`${urlSessionMessages}?session_id=${session}`);
      if (response.status === 200) {
        const messages = response.data;
        messages.forEach((message) => {
          if (JSON.parse(message)) {
            emit('append-message', JSON.parse(message));
          } else {
            throw new Error('Error parsing message');
          }
        });
      } else {
        console.error('Error retrieving session messages:', response.error);
      }
    } catch (error) {
      console.error('Error retrieving session messages:', error);
    }
  };

  const createSession = async () => {
    try {
      const uniqid = checkOrCreateUniqid();
      const product = 'product1';
      const response = await apiConsumer.post(urlSessions, {
        product: product,
        uniqid: uniqid,
      });
      sessions.value.unshift(response.data.session_id);

      // select the newly created session
      session_id.value = response.data.session_id;
      selectSession(session_id.value);
      emit('clear-chat');
    } catch (error) {
      console.error('Error creating session', error);
    }
  };

  const destroyAllSessions = async () => {
    try {
      await apiConsumer.delete(urlSessions);
      sessions.value = [];
      session_id.value = null;
      emit('update:selectedSession', null);
      emit('clear-chat');

      // destroy cookie unique id
      checkOrCreateUniqid(true);
    } catch (error) {
      console.error('Error destroying sessions', error);
    }
  };

  const selectSession = (session) => {
    session_id.value = session;
    emit('clear-chat');
    emit('update:selectedSession', session);
    retrieveSessionMessages(session);
  };

  watch(
    session_id,
    (newValue) => {
      localStorage.setItem('session_id', newValue);
    }, { deep: true }
  );

  onMounted(fetchSessions);
</script>../../config